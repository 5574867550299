<template>
  <span style="display: inline">
    <span>{{ theText }}</span>
    <span v-if="withTooltip">
      <VueTooltip
        v-if="text && text.length > max"
        :color="whiteBackground ? 'white' : 'primary'"
        :dots="true"
        :text="text"
        :text-class="whiteBackground ? 'black--text' : ''"
      />
    </span>
    <span v-else>
      {{ text && text.length > max ? "..." : "" }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    max: {
      type: [Number, String],
      default: "",
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
    withTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    theText() {
      return this.text ? this.text.slice(0, this.max) : "-";
    },
  },
};
</script>

<style lang="scss" scoped></style>
