var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticStyle: {
      "display": "inline"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.theText))]), _vm.withTooltip ? _c('span', [_vm.text && _vm.text.length > _vm.max ? _c('VueTooltip', {
    attrs: {
      "color": _vm.whiteBackground ? 'white' : 'primary',
      "dots": true,
      "text": _vm.text,
      "text-class": _vm.whiteBackground ? 'black--text' : ''
    }
  }) : _vm._e()], 1) : _c('span', [_vm._v(" " + _vm._s(_vm.text && _vm.text.length > _vm.max ? "..." : "") + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }